// SIMPLE GRID - SASS/SCSS

// universal
html {
  background-color: #fff;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

// utility

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

.hidden-sm {
  display: none;
}

// grid
$width: 96%;
$gutter: 4%;
$breakpoint-small: 33.75em; // 540px
$breakpoint-med: 45em; // 720px
$breakpoint-large: 60em; // 960px

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: $breakpoint-small) {
    width: 80%;
  }

  @media only screen and (min-width: $breakpoint-large) {
    width: 100%;
    max-width: 1200px;
  }
}

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;

  &.col-center {
    float: none;
    margin: 0.5rem auto;
  }
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: $width;
}

.col-1-sm {
  width: calc(calc($width / 12) - calc($gutter * 11 / 12));
}

.col-2-sm {
  width: calc(calc($width / 6) - calc($gutter * 10 / 12));
}

.col-3-sm {
  width: calc(calc($width / 4) - calc($gutter * 9 / 12));
}

.col-4-sm {
  width: calc(calc($width / 3) - calc($gutter * 8 / 12));
}

.col-5-sm {
  width: calc(calc($width / (12 / 5)) - calc($gutter * 7 / 12));
}

.col-6-sm {
  width: calc(calc($width / 2) - calc($gutter * 6 / 12));
}

.col-7-sm {
  width: calc(calc($width / (12 / 7)) - calc($gutter * 5 / 12));
}

.col-8-sm {
  width: calc(calc($width / (12 / 8)) - calc($gutter * 4 / 12));
}

.col-9-sm {
  width: calc(calc($width / (12 / 9)) - calc($gutter * 3 / 12));
}

.col-10-sm {
  width: calc(calc($width / (12 / 10)) - calc($gutter * 2 / 12));
}

.col-11-sm {
  width: calc(calc($width / (12 / 11)) - calc($gutter * 1 / 12));
}

.col-12-sm {
  width: $width;
}

@media only screen and (min-width: $breakpoint-med) {
  .col-1 {
    width: calc(calc($width / 12) - calc($gutter * 11 / 12));
  }
  .col-2 {
    width: calc(calc($width / 6) - calc($gutter * 10 / 12));
  }
  .col-3 {
    width: calc(calc($width / 4) - calc($gutter * 9 / 12));
  }
  .col-4 {
    width: calc(calc($width / 3) - calc($gutter * 8 / 12));
  }
  .col-5 {
    width: calc(calc($width / (12 / 5)) - calc($gutter * 7 / 12));
  }
  .col-6 {
    width: calc(calc($width / 2) - calc($gutter * 6 / 12));
  }
  .col-7 {
    width: calc(calc($width / (12 / 7)) - calc($gutter * 5 / 12));
  }
  .col-8 {
    width: calc(calc($width / (12 / 8)) - calc($gutter * 4 / 12));
  }
  .col-9 {
    width: calc(calc($width / (12 / 9)) - calc($gutter * 3 / 12));
  }
  .col-10 {
    width: calc(calc($width / (12 / 10)) - calc($gutter * 2 / 12));
  }
  .col-11 {
    width: calc(calc($width / (12 / 11)) - calc($gutter * 1 / 12));
  }
  .col-12 {
    width: $width;
  }

  .hidden-sm {
    display: block;
  }
}
