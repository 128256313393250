
.questionContainer {
  padding: 24px 0;

  .question {
    width: 100%;
    background-color: #fff;
    color: #444;
    margin-bottom: 4px;

    a {
      color: #444;

      .question {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        width: 100%;
        .questionHeader {
          flex: 1 1 auto;
          margin: 0;
          padding: 8px;
        }
        .toggleIcon {
          display: flex;
          flex: 0 0 50px;
          align-items: center;
          justify-content: center;
          transition: transform 0.15s ease-out;

          &.closed {
            transform: rotate(90deg);
          }
          &.open {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .answer {

      transition:max-height 0.3s ease-in-out;

      &.closed {
        max-height: 0;
        overflow: hidden;
      }
      &.open {
        max-height: 1500px;
      }

      p {
        border-top: 2px dashed #14add8;
        padding: 8px;

        &.closed {
          display: none;
        }
        &.open {
          display: block;
        }
      }
    }
  }
}