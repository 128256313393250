@import "../theme/colors";

.likert {
  position: relative;

  .choices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .choice {
      padding: 2px;
      text-align: center;
      flex: 1;

      @media screen and (max-width: 320px) {
        font-size: 1rem;
        line-height: 11px;
      }

      @media screen and (max-width: 240px) {
        font-size: 0.8rem;
        line-height: 10px;
      }
    }

    .side {
      flex: 1
    }

    .center {
      flex: 4
    }
  }

  .trapezoids {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 50px;
    z-index: 1;

    @media screen and (max-width: 311px) {
      top: 46px;
    }

    .element {
      width: 100%;
      height: 0;

      // border depending on maximum width
      border-left: 40vw solid transparent;
      border-right: 40vw solid transparent;

      @media screen and (min-width: 750px) {
        border-left: 320px solid transparent;
        border-right: 320px solid transparent;
      }
    }

    .top {
      border-top: 10px solid $cool_c0;
    }

    .bottom {
      border-bottom: 10px solid $cool_c0;
      margin-top: 9px;
    }
  }

  .noUi-horizontal {

    box-shadow: None !important;
    border: None !important;
    border-radius: 0 !important;
    height: 29px;
    margin: 27px 0 24px 0;
    background-color: aliceblue;
    background: linear-gradient(to right, $secondary, $cool_c1, $secondary);

    .noUi-handle {
      top: -3px;
    }
  }

  .unclear {
    margin-top: 18px;
    padding-top: 12px;
    border-top: 1px solid $cool_c3;

    .input_unclear {
      margin-top: 6px;
      display: flex;
      flex-direction: column;

      input {
        padding: 2px;
        margin: 6px 0;
      }
    }
  }
}