@import "colors";


.loadingSpinner {
  padding: 32px;
  margin-top: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.clickable {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    filter: alpha(opacity=70); /* For IE8 and earlier */
  }
}

.right {
  text-align: right;
  margin-left: auto;
}

.center {
  text-align: center;
}

.smallSpacer {
  padding: 3px 0;
}

.spacer {
  padding: 6px 0;
}

.bigSpacer {
  padding: 12px 0;
}

.thin {
  font-weight: 300;
}

.heavy {
  font-weight: 600;
}

.textShadow {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.xsmall {
  font-size: 1.1rem;
  line-height: 1.2rem;
  font-weight: 400;

  @media screen and (max-width: 440px) {
    font-size: 1rem;
  }

}

.small {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 400;

  @media screen and (max-width: 350px) {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }

  &.heavy {
    font-weight: 600;
  }
}

.medium, h4 {
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 500;
}

.large, h3 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 600;

  @media screen and (max-width: 315px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.xlarge, h2 {
  font-size: 2.6rem;
  line-height: 3rem;
  font-weight: 700;
}

.xxlarge, h1 {
  font-size: 3.2rem;
  line-height: 3.5rem;
  font-weight: 700;

  @media screen and (max-width: 300px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;

  @media screen and (max-width: 300px) {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  p {
    color: #444;
  }
}

.centerWrapper {
  max-width: 900px;
  margin: 0 auto;
}

.hidden {
  display: none;
  filter: alpha(opacity=0); /* For IE8 and earlier */
  opacity: 0;
  height: 0;
}

.bounce {
  -moz-animation: bounce 1.3s infinite;
  -webkit-animation: bounce 1.3s infinite;
  animation: bounce 1.3s infinite;
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  60% {
    -moz-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  60% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  60% {
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}


.blink {
  -webkit-animation: blink 1.5s infinite alternate;
  -moz-animation: blink 1.5s infinite alternate;
  -ms-animation: blink 1.5s infinite alternate;
  -o-animation: blink 1.5s infinite alternate;
  animation: blink 1.5s infinite alternate;
}

@-webkit-keyframes blink {
  from { background-color: $cool_c3; }
  to { background-color: $cool_c0; }
}
@-moz-keyframes blink {
  from { background-color: $cool_c3; }
  to { background-color: $cool_c0; }
}
@-ms-keyframes blink {
  from { background-color: $cool_c3; }
  to { background-color: $cool_c0; }
}
@-o-keyframes blink {
  from { background-color: $cool_c3; }
  to { background-color: $cool_c0; }
}
@keyframes blink {
  from { background-color: $cool_c3; }
  to { background-color: $cool_c0; }
}

.subsection_header {
  display: inline-block;
  font-weight:510;
  margin-right:1rem;
}

.point_header {
  display: inline-block;
  font-weight:510;
  margin-right:1rem;
  font-size: small;
}

::placeholder {
  color: $cool_c4;
}