@import "../theme/colors";

.iq {

  .question {
    max-width: 250px;
    display: block;
    margin: 0 auto;
  }

  .choices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 12px;

    .imageParent {
      width: 22%;
      .choice {
        padding: 2px;
        display: block;
        width: 100%;
        height: auto;
        border-radius: 10px;
        transition: 0.25s;
      }
    }

    .active {
      border: 1px solid $primary;
      box-shadow: 0 0.5em 0.5em -0.4em $primary;
      transform: translateY(-5px);
    }

    .side {
      flex: 1
    }

    .center {
      flex: 4
    }
  }

  .unclear {
    margin-top: 18px;
    padding-top: 12px;
    border-top: 1px solid $cool_c3;

    .input_unclear {
      margin-top: 6px;
      display: flex;
      flex-direction: column;

      input {
        padding: 2px;
        margin: 6px 0;
      }
    }
  }
}