@import "../theme/colors";

.fileContainer {
  background-color: $cool_c2;
  padding: 5px;
  margin-bottom: 5px;
  height: 100px;
  max-width: 150px;


  .file_uploader {
    height: 90px;
    max-width: 140px;

    position: relative;
    border: 1px dashed $cool_c4;
    padding: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    label {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      input {
        display: none;
      }

      .icon_upload {
        margin-bottom: 5px;
      }
    }

    .cancel {
      align-self: flex-end;
      justify-self: flex-end;
      margin-top: -55px;
    }
  }
}