.fabContainer {
  cursor: pointer;
  flex: 0 0 auto;
  flex: none;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  .button {
    flex: 0 0 auto;
    border: none;
    margin: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70); /* For IE8 and earlier */
    }
    &:focus {
      outline: none;
    }
  }
  .title {
    padding-top: 8px;
    position: relative;
    float: left;
    width: auto;
    margin: auto;
    font-weight: 500;
  }
}
