@import "../theme/colors";

.multipleChoice {

  .choices {
    padding-top: 12px;
    .choice {
      padding: 6px 0;
    }
  }

  .unclear {
    margin-top: 18px;
    padding-top: 12px;
    border-top: 1px solid $cool_c3;

    .input_unclear {
      margin-top: 6px;
      display: flex;
      flex-direction: column;

      input {
        padding: 2px;
        margin: 6px 0;
      }
    }
  }
}