@keyframes rai-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rai-spinner {
  width: 1.5em;
  height: 1.5em;
  position: relative;
  margin: 0;
}

.rai-spinner-inner, .rai-spinner-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  margin-left: -0.0625em;
  margin-top: -0.0625em;
}

.rai-spinner-outer {
  border: 0.125em solid #727981;
  opacity: 0.2;
}

.rai-spinner-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-top: 0.125em solid #727981;
  border-right: 0.125em solid transparent !important;
  border-bottom: 0.125em solid transparent !important;
  border-left: 0.125em solid transparent !important;
  animation-name: rai-spinner;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: inherit;
}
