@import "../../theme/colors";

.intakeContainer {
  .sv_row {
    display: flex; // its already flex but for clarity
    align-items: stretch;
  }
}

.sv_p_description {
  padding-left: 20px;
}

ul {
  margin-left: 3rem;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}
