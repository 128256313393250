@import "../theme/colors";

.memory {

  .question {
    max-width: 250px;
    display: block;
    margin: 0 auto;
  }

  .choices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 12px;

    .choice {
      padding: 6px 0;
    }
  }
}