
.alertContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1049;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;

    background-color: rgba(68, 68, 68, .9);
  }

  .alertModal {
    z-index: 1050;
    max-width: 300px;
    width: 100%;
    background: #fff;

    .spacing {
      padding-top: 24px;
    }

    .buttonContainer {
      width: 80%;
      margin: 0 auto 12px;
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}