.revealContainer {
  .revealItem {
    transition: all .2s ease-in-out;
    &.fadeIn {
      visibility: visible;
      opacity: 1;
    }
    &.fadeOut {
      visibility: hidden;
      opacity: .2;
      transform: scale(.5);
    }
  }
}
