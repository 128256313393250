@import "../theme/colors";

.rankingComponent {
  position: relative;
  padding-right: 32px;


  .listWrapper {
    .list {
      cursor: move;
      width: 100%;
      min-height: 30px;

      .item {
        background-color: #3dccc7;
        color: #fff;
        margin: 5px;
        padding: 10px;
      }
    }
  }

  .full {
    border: 1px solid $cool_c4;
  }

  .empty {
    border: 1px dashed $cool_c4;

    .list {
      padding: 12px 0;
    }

  }
}