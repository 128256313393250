@import '../../theme/colors';

$navigatorHeight: 70px;

$breakpoint-small: 33.75em; // 540px
$breakpoint-med: 45em; // 720px
$breakpoint-large: 60em; // 960px

.navigationContainer {
  width: 100%;
  z-index: 900;
  border-bottom: 1px solid $cool_c4;
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

  .navigationWrapper {
    position: relative;
    max-width: 1200px;
    width: 100%;

    margin: auto;
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    transition: all .3s ease;

    @media only screen and (max-width: $breakpoint-med) {
      height: 60px;
    }

    @media only screen and (min-width: $breakpoint-med) {
      height: $navigatorHeight;
    }


    .logoContainer {
      display: flex;
      align-items: center;
      padding: 0 0 0 8px;
      flex: 0 0 auto;
      .logo {
        @media only screen and (max-width: $breakpoint-med) {
          height: 40px;
        }

        @media only screen and (min-width: $breakpoint-med) {
          height: 50px;
        }
      }
    }

    .locale {
      background: $primary_light;
      margin: 0 12px;
      padding: 4px 12px;
      border-radius: 4px
    }

  }
}
