@import '../../theme/colors';

.footerContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    .banner {
        max-width: 570px;
        width: 100%;
        z-index: 100;
        margin: -1px;
    }

    .footerWrapper {

        // if screen increases, make margin negative so that it falls over the banner
        @media screen and (min-width: 570px) {
            margin-top: -60px;
            padding-top: 60px
        }

        width: 100%;
        background-color: $secondary;

        .footer {
            margin: 0 auto;
            max-width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 12px;

            .footer_button {
                padding-top: 24px
            }
        }
    }
}