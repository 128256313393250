@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,500,700');
@import './main';
@import './colors';
@import './grid';
@import './survey';

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: .6rem;
  font-family: 'Lato', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  padding: 0;
  margin: 0;
}

body {

  &.disableScroll {
    overflow: hidden;
  }

  b {
    font-weight: 500;
  }
  a {
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }

  font-size: 1.5rem;
  line-height: 2rem;
  // BASIC OVERRIDES

  button {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
  }

  button:active, button:focus, button.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
  }

}

#root {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  .app {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

