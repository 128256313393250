@import '../../theme/colors';

.homeContainer {

  .message {
    margin-top: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}