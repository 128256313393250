
.buttonComponentContainer {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid;

  .button {
    cursor: pointer;
    width: 100%;
    border: none;
    min-height: 50px;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    border-radius: 2px;

    &:hover {
      opacity: 0.7;
      filter: alpha(opacity=70); /* For IE8 and earlier */
    }

    .buttonWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.padding {
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .title {
        font-weight: bold;
        text-align: center;
        display: flex;
        font-size: 1.7rem;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
      }

      .icon {
        margin-top: -3px;
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        align-content: center;
      }

      .ri {
        justify-content: center;
        align-items: center;
      }

      .le {
        justify-content: center;
        align-items: center;
      }
    }
  }
}
