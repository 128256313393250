@import '../../theme/colors';

.startComponent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;

  .startHeaderWrapper {

    width: 100%;


    @media screen and (min-width: 344px) {
      background-color: $secondary_light;
    }

    .startHeader {
      max-width: 600px;
      margin:0 auto;

      background-repeat: no-repeat;
      background-size: auto 170px;

      @media screen and (min-width: 344px) {
        background-image: none !important;
      }

      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      align-content: center;

      .headerButton {
        padding: 12px;
        width: 90px;

        @media screen and (max-width: 344px) {
          display: none;
        }
      }

      .startTitle {

        padding: 18px;

        .time {
          display: flex;
          flex-direction: row;
          justify-items: flex-start;
          align-items: center;
        }
      }

    }
  }

  .startBody {
    padding: 24px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
  }

  .startButton {

    margin: 24px auto;
    width: 240px;


  }
}