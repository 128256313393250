@import '../../theme/colors';

.cd-panel__close {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 60px;
  /* image replacement */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.cd-panel__close::before, .cd-panel__close::after {
  /* close icon created in CSS */
  content: '';
  position: absolute;
  top: 35px;
  left: 20px;
  height: 3px;
  width: 20px;
  background-color: #fff;
  /* this fixes a bug where pseudo elements are slighty off position */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cd-panel__close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cd-panel__close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.cd-panel__close:hover {
  background-color: $secondary_light;
}

.cd-panel__close:hover::before, .cd-panel__close:hover::after {
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.cd-panel__close:hover::before {
  -webkit-transform: rotate(220deg);
  -ms-transform: rotate(220deg);
  transform: rotate(220deg);
}

.cd-panel__close:hover::after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.is_visible .cd-panel__close::before {
  -webkit-animation: cd-close-1 0.6s 0.3s;
  animation: cd-close-1 0.6s 0.3s;
}

.is_visible .cd-panel__close::after {
  -webkit-animation: cd-close-2 0.6s 0.3s;
  animation: cd-close-2 0.6s 0.3s;
}

@-webkit-keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-webkit-keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}