@import '../../theme/colors';

.helpContainer {

  .helpButton {
    top: 100px;
    z-index: 800;
    position: absolute;
    padding: 10px 18px 24px 18px;
    background: $accent;
    transition: all 0.2s;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }

  .ar {
    left: -30px;
    transform: rotate(90deg);
    &:hover {
      transform: rotate(90deg) translateY(-12px);
    }
  }

  .en {
    right: -34px;
    transform: rotate(-90deg);
    &:hover {
      transform: rotate(-90deg) translateY(-12px);
    }
  }

  .cd-panel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1049;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.6s;
    transition: visibility 0s 0.6s;

    &:after {
      /* overlay layer */
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      cursor: pointer;
      -webkit-transition: background 0.3s 0.3s;
      transition: background 0.3s 0.3s;
    }

    &.is_visible {
      visibility: visible;
      -webkit-transition: visibility 0s 0s;
      transition: visibility 0s 0s;
    }

    &.is_visible::after {
      background: rgba(0, 0, 0, 0.6);
      -webkit-transition: background 0.3s 0s;
      transition: background 0.3s 0s;
    }
  }

  .helpHeader {
    right: 0;
    top: -12px;

    padding: 18px 70px 48px 18px;

    position: fixed;
    width: 90%;
    max-width: 500px;

    height: 50px;
    line-height: 50px;
    background: $primary_light;
    z-index: 2;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    -webkit-transition: -webkit-transform 0.3s 0s;
    transition: -webkit-transform 0.3s 0s;
    transition: transform 0.3s 0s, -webkit-transform 0.3s 0s;
    -webkit-transform: translateY(-55px);
    -ms-transform: translateY(-55px);
    transform: translateY(-55px);
  }

  .is_visible .helpHeader {
      -webkit-transition: -webkit-transform 0.3s 0.3s;
      transition: -webkit-transform 0.3s 0.3s;
      transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
      -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
      transform: translateY(0px);
  }

  .cd-panel__container {
    position: fixed;
    width: 90%;
    max-width: 500px;
    height: 100%;
    top: 0;
    background: $secondary;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    transition: -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;

    right: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  .is_visible .cd-panel__container {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  .cd-panel__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 70px 5%;
    overflow: auto;
    /* smooth scrolling on touch devices */
    -webkit-overflow-scrolling: touch;

    .help_image {
      display: flex;
      flex-direction: column;

      img {
        margin: 0 auto
      }
    }
  }
}