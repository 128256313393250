@import '../../theme/colors';

.applicationContainer {

  .header {
    padding: 24px 0 48px 0;

    .help_arrow_ar {
      margin-top: 90px;
      left: 16px;
      position: absolute;
      width: 32px;

      img {
        -moz-transform: scaleX(-1);    /* Gecko */
        -o-transform: scaleX(-1);      /* Opera */
        -webkit-transform: scaleX(-1); /* Webkit */
        transform: scaleX(-1);         /* Standard */
      }
    }

    .help_arrow_en {
      margin-top: 90px;
      right: 11px;
      position: absolute;
      width: 32px;
    }
  }

  .selector {
    max-width: 340px;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: row;
    justify-items: center;

    .stage {

      display: flex;
      flex-direction: column;
      align-items: center;

      .circle {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: -3px;
        z-index: 100;
        flex-grow: 0;

        &.smaller {
          width: 60px;
          height: 60px;
        }

        &.bigger {
          width: 80px;
          height: 80px;
        }
      }

      .descriptor {
        margin-top: 76px;
        position: absolute;

        &.next {
          margin-top: 85px;
        }
      }

      .completedIcon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $green;
        display: flex;
        justify-items: center;
        align-items: center;

        position: absolute;
        margin: 40px 25px;
      }
    }

    .line {
      margin-top: 32px;

      height: 2px;
      background-color: $cool_c3;
      flex-grow: 1;

      &.done {
        background-color: $secondary;
      }

    }
  }

  .continueButton {
    max-width: 240px;
    margin: 120px auto 0;
  }

  .backButton {
    max-width: 240px;
    margin: 120px auto 0;
  }
}