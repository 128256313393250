@import "../theme/colors";

.phone {
  display: flex;
  flex-direction: row;

  .country_select {
    max-width: 80px;
  }

  .phone_input {
    margin-left: 6px;
  }
}
