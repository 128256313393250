// Brand colors
$secondary: #077D9B;
$secondary_light: #25A7C4;
$secondary_lighter: #7DD9F1;

$primary: #00B7E4;
$primary_light: #B8E5F3;
$primary_lighter: #F2FCFF;

$accent: #FFC72A;

$green: #82D62E;
$green_light: #BFEE90;
$green_lighter: #D2F2B2;

$darkgray: #444444;
$alert: #D32E6D;

$whatsapp: #65d072;
$facebook: #3b5998;
$facebookMessenger: #1981ff;
$google: #5588EA;

// Cool grays
$cool_c0: #f4f4f6;
$cool_c1: #f9f9fe;
$cool_c2: #f1f1f6;
$cool_c3: #d9d9de;
$cool_c4: #d1d1d8;
$cool_c5: #757577;

.white {
  color: #fff;
}

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.primary_light {
  color: $primary_light;
}

.secondary_light {
  color: $secondary_light;
}


.green {
  color: $green
}
.green_light {
  color: $green_light
}

.darkgray {
  color: $darkgray
}

.alert {
  color: $alert
}

.whatsapp {
  color: $whatsapp
}

.facebook {
  color: $facebook
}

.facebookMessenger {
  color: $facebookMessenger
}

.google {
  color: $google
}

.cool_c1 {
  color: $cool_c1;
}

.cool_c2 {
  color: $cool_c2;
}

.cool_c3 {
  color: $cool_c3;
}

.cool_c4 {
  color: $cool_c4;
}

.cool_c5 {
  color: $cool_c5;
}